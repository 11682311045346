<template>
    <div class="contact-page">
        <base-nav />
        
        <base-inner-banner img-type="event"  />

        <div class="row" style="background-color:#f6f6f6;padding-bottom:63px;">
            <div class="col-lg-12">
                <h1 class="text-center page-h1 mb-0">{{ $t('Nav_contactUs')}} </h1>
                <h3 v-if="flag_currentStep==0" class="slogan">{{ $t("ContactUs.Description")}}</h3>
                <h3 v-if="flag_currentStep==1" class="slogan">{{ $t("ContactUs.Success_Msg")}}</h3>
            </div>
            <div class="col-lg-3"></div>
            <!-- <form class="form-body col-lg-6" @submit.prevent>
                <div class="row" >
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="name">姓名</label>
                        <span style="color:red">*</span>
                        <CInput v-if="!flag_isMobile" type="text"  id="name" class="input-size input-normal" @keyup="checkIfBlank('name')" @change="checkIfBlank('name')" @focusout="checkIfBlank('name')" @focusin="checkIfBlank('name')"  :value.sync="$v.form.name.$model" required />
                        <CInput v-if="flag_isMobile" type="text" class="input-size input-normal" id="name" @touchend="checkIfBlank('name')" @change="checkIfBlank('name')" @touchcancel="checkIfBlank('name')" @touchstart="checkIfBlank('name')" :value.sync="$v.form.name.$model" required />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="phone">聯絡電話</label>
                        <CInput class="input-size" :value.sync="$v.form.phone.$model" type="text" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="email">Email</label>
                        <span style="color:red">*</span>
                        <CInput v-if="!flag_isMobile" type="text"  id="mail" class="input-size input-normal" @keyup="checkIfBlank('mail')" @change="checkIfBlank('mail')" @focusout="checkIfBlank('mail')" @focusin="checkIfBlank('mail')"  :value.sync="$v.form.mail.$model" required />
                        <CInput v-if="flag_isMobile" type="text" class="input-size input-normal" id="mail" @touchend="checkIfBlank('mail')" @change="checkIfBlank('mail')" @touchcancel="checkIfBlank('mail')" @touchstart="checkIfBlank('mail')" :value.sync="$v.form.mail.$model" required />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="company">公司名稱</label>
                        <span style="color:red">*</span>
                        <CInput v-if="!flag_isMobile" type="text"  id="company" class="input-size input-normal" @keyup="checkIfBlank('company')" @change="checkIfBlank('company')" @focusout="checkIfBlank('company')" @focusin="checkIfBlank('company')"  :value.sync="$v.form.company.$model" required />
                        <CInput v-if="flag_isMobile" type="text" class="input-size input-normal" id="company" @touchend="checkIfBlank('company')" @change="checkIfBlank('company')" @touchcancel="checkIfBlank('company')" @touchstart="checkIfBlank('company')" :value.sync="$v.form.company.$model" required />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="jobTitle">您的職位</label>
                        <CInput class="input-size" :value.sync="$v.form.jobTitle.$model" type="text" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="request">貴司的專案需求</label>
                        <CInput class="input-size" :value.sync="$v.form.request.$model" type="text" />
                    </div>
                    <div class="col-lg-12 btn-container">
                        <button class="btn-primary btn btn-contact" @click="sendEmail" :disabled="!flag_correctForm">送出表單</button>
                    </div>
                </div>
            </form> -->
            <contact-form v-if="flag_currentStep==0"/>
            <contact-success v-if="flag_currentStep==1"/>
            <div class="col-lg-3"></div>

        </div>

        

        
        <!-- <div style="display:block;margin-bottom:90px;text-align:center">
            <button class="btn-primary btn btn-contact" @click="sendEmail">聯絡我們</button>
        </div> -->

        <base-footer />
    </div>
</template>

<script>
import ContactForm from '../../components/contact/ContactForm.vue'
import ContactSuccess from '../../components/contact/ContactSuccess.vue'

const defaultState = () => {
    return {
        flag_currentStep: 0
    }
}

export default {
    name: 'ContactUs',
    components: {
        ContactForm,
        ContactSuccess
    },
    data() {
        return Object.assign({}, defaultState())
    },

    computed: {},

    created() {
        window.scrollTo(0,0);
    },
    

}
</script>

<style lang="scss" scoped>
.contact-page{
    background: #f6f6f6;
}

.slogan {
    text-align: center;
    font-size: 22px;
    line-height: 2.5rem;
}


@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 950px) {
}

@media screen and (max-width: 576px) {

}

</style>