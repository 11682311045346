<template>
    <form class="form-body col-lg-6" @submit.prevent>
        <!-- Please kindy send email to sales@aira.com.tw -->
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label for="name">{{$t("ContactUs.Form.Name")}}</label>
                <span style="color:red">*</span>
                <CInput v-if="!flag_isMobile" type="text" id="name" class="input-size input-normal"
                    @input="(evt)=>checkIfBlank('name', evt)" :value.sync="$v.form.name.$model" required />
                <CInput v-if="flag_isMobile" type="text" class="input-size input-normal" id="name"
                    @change="(evt)=>checkIfBlank('name', evt)" :value.sync="$v.form.name.$model" required />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label for="phone">{{$t("ContactUs.Form.Tel")}}</label>
                <CInput class="input-size" :value.sync="$v.form.phone.$model" type="text" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label for="email">{{$t("ContactUs.Form.Email")}}</label>
                <span style="color:red">*</span>
                <CInput v-if="!flag_isMobile" type="text" id="mail" class="input-size input-normal"
                    @input="(evt)=>checkIfBlank('mail', evt)" :value.sync="$v.form.mail.$model" required />
                <CInput v-if="flag_isMobile" type="text" class="input-size input-normal" id="mail"
                    @change="(evt)=>checkIfBlank('mail', evt)" :value.sync="$v.form.mail.$model" required />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label for="company">{{$t("ContactUs.Form.Company")}}</label>
                <span style="color:red">*</span>
                <CInput v-if="!flag_isMobile" type="text" id="company" class="input-size input-normal"
                    @input="(evt)=>checkIfBlank('company', evt)" :value.sync="$v.form.company.$model" required />
                <CInput v-if="flag_isMobile" type="text" class="input-size input-normal" id="company"
                @change="(evt)=>checkIfBlank('company', evt)" :value.sync="$v.form.company.$model" required />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label for="jobTitle">{{$t("ContactUs.Form.JobTitle")}}</label>
                <CInput class="input-size" :value.sync="$v.form.jobTitle.$model" type="text" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label for="request">{{$t("ContactUs.Form.Request")}}</label>
                <CInput class="input-size" :value.sync="$v.form.request.$model" type="text" />
            </div>
            <div class="col-lg-12 btn-container">
                <div v-if="flag_formReminder" style="display:block;text-align:center">
                    <span style="color:red">{{$t("ContactUs.Form.Error_Msg")}}</span>
                </div>
                <button class="btn-primary btn btn-contact" @click="sendEmail"
                    :disabled="!flag_correctForm">{{$t("ContactUs.Submit")}}</button>
            </div>
        </div>
    </form>
</template>

<script>
    // import emailjs from 'emailjs-com';
    import apiClient from '@/apis/apiClient';

    import { validationMixin } from 'vuelidate'
    import { required, email } from "vuelidate/lib/validators"

    const defaultState = () => {
        return {
            form: {
                name: "",
                phone: "",
                mail: "",
                company: "",
                jobTitle: "",
                request: ""
            },
            flag_correctForm: false,
            flag_isMobile: false,
            flag_formReminder: false,
            nameCheck: false,
            mailCheck: false,
            companyCheck: false,
        }
    }

    export default {
        name: 'ContactForm',

        data() {
            return Object.assign({}, defaultState())
        },

        computed: {},

        created() {
        },
        mounted() {
            this.detectMob();

            this.checkIfBlank('name', '');
            this.checkIfBlank('mail', '');
            this.checkIfBlank('company', '');

        },
        mixins: [validationMixin],
        validations: {
            form: {
                name: { required },
                phone: {},
                mail: { required, email },
                company: { required },
                jobTitle: {},
                request: {}
            },
            flag_correctForm: false,

            nameCheck: false,
            mailCheck: false,
            companyCheck: false,
        },
        methods: {
            detectMob() {
                if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)
                ) {
                    this.flag_isMobile = true;
                } else {
                    this.flag_isMobile = false;
                }
            },
            async sendEmail() {
                const me = this;
                // const contactEmail = {
                //     name: me.form.name,
                //     phone: me.form.phone,
                //     mail: me.form.mail,
                //     company: me.form.company,
                //     jobTitle: me.form.jobTitle,
                //     request: me.form.request
                // }

                let subject = `新聯絡訊息 from ${me.form.company} 的 ${me.form.name}`;
                let content = "<div><p>Hello ,</p>"
                    + "<p>以下是用戶填單的資訊:</p>"
                    + `<p style="padding:12px;border-left:4px solid #d0d0d0;font-style:italic">姓名：${me.form.name}</p>`
                    + `<p style="padding:12px;border-left:4px solid #d0d0d0;font-style:italic">聯絡電話：${me.form.phone}</p>`
                    + `<p style="padding:12px;border-left:4px solid #d0d0d0;font-style:italic">Email：${me.form.mail}</p>`
                    + `<p style="padding:12px;border-left:4px solid #d0d0d0;font-style:italic">公司名稱：${me.form.company}</p>`
                    + `<p style="padding:12px;border-left:4px solid #d0d0d0;font-style:italic">職位：${me.form.jobTitle}</p>`
                    + `<p style="padding:12px;border-left:4px solid #d0d0d0;font-style:italic">專案需求：${me.form.request}</p>`
                    + `<p>&nbsp;</p>`
                    + `<p>Best wishes,</p>`
                    + `<p><br>Aira Email service</p>`
                    + `</div>`;

                const apiUri = '/createEventSMTP';

                // emailjs.send('service_89uw1cs', 'template_wi5ymci', contactEmail, '3edVly2pPP74aNjShiGIS')
                apiClient.post(apiUri, { subject, content })
                    .then((res) => {
                        console.log( res) ;
                        const me = this;
                        if (res.status == 200) {
                            me.flag_formReminder = false;
                            me.$parent.flag_currentStep = 1;
                        } else {
                            me.flag_formReminder = true;
                            me.form = {
                                name: "",
                                phone: "",
                                mail: "",
                                company: "",
                                jobTitle: "",
                                request: ""
                            };
                            me.flag_correctForm = false;
                            return me.flag_correctForm
                        }
                    }).catch((err) => {
                        console.error(err)
                    })
            },
            checkIfBlank(fieldname, evtValue) {
                const me = this;

                switch (fieldname) {
                    case "name": {
                        let inputElement = document.getElementById('name');

                        if (evtValue.replace(/\s/g, "").length == 0) {
                            inputElement.classList.add('input-alert');
                            inputElement.classList.remove('input-normal');
                            inputElement.style.border = '1px solid #dc3545';

                            me.nameCheck = false;
                            me.flag_correctForm = me.nameCheck && me.mailCheck && me.companyCheck;
                            return me.nameCheck;
                        }
                        else {
                            inputElement.classList.remove('input-alert');
                            inputElement.classList.add('input-normal');
                            inputElement.style.border = '1px solid #80bdff';

                            me.nameCheck = true;
                            me.flag_correctForm = me.nameCheck && me.mailCheck && me.companyCheck;
                            return me.nameCheck;
                        }

                    }
                    case "mail": {
                        let inputElement2 = document.getElementById('mail');

                        console.log("email", evtValue );

                        if (evtValue === '') {
                            inputElement2.classList.add('input-alert');
                            inputElement2.classList.remove('input-normal');
                            inputElement2.style.border = '1px solid #dc3545';

                            console.log("email 1");
                            me.mailCheck = false;
                            me.flag_correctForm = me.nameCheck && me.mailCheck && me.companyCheck;
                            return me.mailCheck;
                        }
                        else if (evtValue.match(/[a-z0-9]+@[a-z0-9]+\.[a-z0-9]/)) {
                            inputElement2.classList.remove('input-alert');
                            inputElement2.classList.add('input-normal');
                            inputElement2.style.border = '1px solid #80bdff';

                            console.log("email 2");
                            me.mailCheck = true;
                            me.flag_correctForm = me.nameCheck && me.mailCheck && me.companyCheck;
                            return me.mailCheck;
                        }
                        else {
                            inputElement2.classList.add('input-alert');
                            inputElement2.classList.remove('input-normal');
                            inputElement2.style.border = '1px solid #dc3545';

                            console.log("email 3");

                            me.mailCheck = false;
                            me.flag_correctForm = me.nameCheck && me.mailCheck && me.companyCheck;
                            return me.mailCheck;
                        }
                    }
                    case "company": {
                        let inputElement2 = document.getElementById('company');
                        if (evtValue.replace(/\s/g, "").length == 0) {
                            inputElement2.classList.add('input-alert');
                            inputElement2.classList.remove('input-normal');
                            inputElement2.style.border = '1px solid #dc3545';

                            me.companyCheck = false;
                            me.flag_correctForm = me.nameCheck && me.mailCheck && me.companyCheck;
                            return me.companyCheck;
                        }
                        else {
                            inputElement2.classList.remove('input-alert');
                            inputElement2.classList.add('input-normal');
                            inputElement2.style.border = '1px solid #80bdff';

                            me.companyCheck = true;
                            me.flag_correctForm = me.nameCheck && me.mailCheck && me.companyCheck;
                            return me.companyCheck;
                        }
                    }

                }
            },
        }
    }
</script>

<style lang="scss">
    .form-body {
        background-color: #ffffff;
        font-weight: 400;
        font-size: 24px;
        margin-top: 60px;
        padding: 40px;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
        border: solid 1px #ebebeb;
    }

    .input-size {
        max-width: 400px;
    }

    .input-normal:focus {
        outline: none !important;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        border-radius: 0.25rem
    }

    .input-alert:focus {
        outline: none !important;
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
        border-radius: 0.25rem
    }

    .btn-container {
        display: block;
        margin-top: 30px;
        text-align: center
    }

    .btn-contact {
        height: 60px;
        width: 300px;
        font-size: 33px;
        border-radius: 0.5rem;
    }

    @media screen and (max-width: 1024px) {}

    @media screen and (max-width: 950px) {
        .input-size {
            max-width: 300px;
        }
    }

    @media screen and (max-width: 576px) {
        .input-size {
            max-width: unset
        }

    }
</style>